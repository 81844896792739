.App {
  min-height: 100vh;
  position: relative;
  background-color: #f4f5fd;
}

.paginationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1em;
}

.errorContainer {
  height: 100vh;
  min-height: 100vh;
  padding-top: 2%;
}
